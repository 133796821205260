<template>
    <div class="content-body">
        <div class="container-fluid">
            <div class="row">
                <div class="col-12 col-xl-4 col-xxl-4" ref="deposit">
                    <div class="card">
                        <div class="card-header">
                            <h4 class="card-title">Make a new deposit</h4>
                        </div>
                        <div class="card-body">
                            <div class="col-12">
                                <label class="col-form-label font-weight-bold">Select a plan</label>
                                <select class="form-control" v-model="plan">
                                    <optgroup :label="key" v-for="(value, key, idx) in plans" :key="'plan_'+idx">
                                        <option :value="key+'-'+key2" v-for="(value2, key2, idx2) in value" :key="'plan_1_'+idx2">
                                            {{key2}}
                                        </option>
                                    </optgroup>
                                </select>
                            </div>
                            <div class="col-12 mt-3">
                                <label class="col-form-label font-weight-bold">Enter an amount</label>
                                <input v-model.number="deposit.amountDeposited" type="number" class="form-control" placeholder="amount">
                            </div>
                            <div class="col-12 mt-3">
                                <label class="col-form-label font-weight-bold">Choose your payment method</label>
                                <div class="form-group mb-0">
                                    <label class="radio-inline mr-3">
                                        <input type="radio" v-model="deposit.paidThrough" name="optradio" value="BALANCE"> Balance
                                    </label>
                                    <label class="radio-inline mr-3">
                                        <input type="radio" v-model="deposit.paidThrough" name="optradio" value="WALLET"> Wallet
                                    </label>
                                </div>
                            </div>
                            <div class="col-12 mt-3 text-center">
                                <button @click.prevent="validateInput" class="btn btn-outline-info">Make Deposit</button>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-12 col-xl-8 col-xxl-8" v-if="deposit.plan&&deposit.package">
                    <div class="card">
                        <div class="card-header border-0 pb-0">
                            <h2 class="card-title">
                                {{deposit.plan}} <span class="text-info">[{{deposit.package}}]</span>
                            </h2>
                        </div>
                        <div class="card-body pb-0">
                            <p>
                                Here is a summary of the selected package
                            </p>
                            <ul class="list-group list-group-flush">
                                <li class="list-group-item d-flex px-0 justify-content-between">
                                    <strong class="text-capitalize">Daily Profit</strong>
                                    <span class="mb-0">${{ plans[deposit.plan][deposit.package]['dailyProfit'] }}</span>
                                </li>
                                <li class="list-group-item d-flex px-0 justify-content-between">
                                    <strong class="text-capitalize">Min Deposit</strong>
                                    <span class="mb-0">${{ plans[deposit.plan][deposit.package]['minDeposit'] }}</span>
                                </li>
                                <li class="list-group-item d-flex px-0 justify-content-between">
                                    <strong class="text-capitalize">Max Deposit</strong>
                                    <span class="mb-0">${{ plans[deposit.plan][deposit.package]['maxDeposit'] }}</span>
                                </li>
                                <li class="list-group-item d-flex px-0 justify-content-between" v-if="plans[deposit.plan][deposit.package]['lifeSpan'] > 0">
                                    <strong class="text-capitalize">lifeSpan</strong>
                                    <span class="mb-0">{{ plans[deposit.plan][deposit.package]['lifeSpan'] }} Months</span>
                                </li>
                                <li class="list-group-item d-flex px-0 justify-content-between">
                                    <strong class="text-capitalize">referral Bonus</strong>
                                    <span class="mb-0">{{ plans[deposit.plan][deposit.package]['referralBonus'] }}%</span>
                                </li>
                                <li class="list-group-item d-flex px-0 justify-content-between">
                                    <strong class="text-capitalize">withdrawal</strong>
                                    <span class="mb-0">{{ plans[deposit.plan][deposit.package]['withdrawal'] }}</span>
                                </li>
                            </ul>
                        </div>
                        <div class="card-footer pt-0 pb-0 text-center">
                            <div class="row">
                                <div class="col-4 pt-3 pb-3 border-right">
                                    <h3 class="mb-1 text-primary">
                                        ${{ plans[deposit.plan][deposit.package]['minDeposit'] }}
                                    </h3>
                                    <span>Min Deposit</span>
                                </div>
                                <div class="col-4 pt-3 pb-3 border-right">
                                    <h3 class="mb-1 text-primary">
                                        ${{ plans[deposit.plan][deposit.package]['maxDeposit'] }}
                                    </h3>
                                    <span>Max Deposit</span>
                                </div>
                                <div class="col-4 pt-3 pb-3">
                                    <h3 class="mb-1 text-primary">
                                        ${{ plans[deposit.plan][deposit.package]['dailyProfit'] }}
                                    </h3>
                                    <span>Daily Profit</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <wallet-address v-bind="post_deposit_info"/>
    </div>
</template>

<script>
import Plans from "../../../utils/Plans";
import Deposit from "../../../models/deposit";
import walletAddress from "../../../components/dashboard/modals/walletAddress";

export default {
    name: "Deposit",
    data(){
        return {
            deposit: (new Deposit()).data,
            plans: Plans.all,
            post_deposit_info: {
                crypto: 'Bitcoin',
                amount: 0,
                address: ""
            }
        }
    },
    computed: {
        plan: {
            get(){
                return `${this.deposit.plan}-${this.deposit.package}`
            },
            set(plan){
                const plan_arr = plan.split('-');
                this.deposit.plan = plan_arr[0];
                this.deposit.package = plan_arr[1]
            }
        }
    },
    methods: {
        async proceed(){
            const loader = this.$loading.show({container: this.$refs.deposit});
            let response;
            if(this.deposit.paidThrough === 'BALANCE'){
                response = await this.$store.dispatch('user/getUser', this.$store.getters['user/getUser'].id);
                if(response.status){
                    if(response.data.data.balance < this.deposit.amountDeposited){
                        loader.hide();
                        return toastr.warning("You have insufficient balance to complete this operation")
                    }
                }else{
                    loader.hide();
                    return toastr.warning("An unknown error occurred")
                }
            }
            response = await this.$store.dispatch('deposit/addDeposit', this.deposit);
            if(response.status){
                this.post_deposit_info.address = response.data;
                this.post_deposit_info.amount = this.deposit.amountDeposited;
                $('#walletAddress').modal('show');
                this.resetDepositObj();
                toastr.success("Operation successful")
            }else{
                toastr.warning(response.message)
            }
            return loader.hide()
        },
        async validateInput(){
            const active_deposit = this.plans[this.deposit.plan][this.deposit.package];
            if(this.deposit.amountDeposited < active_deposit.minDeposit){
                return toastr.info("The amount specified is lower than the minimum amount for the selected package");
            }
            if(this.deposit.amountDeposited > active_deposit.maxDeposit){
                return toastr.info("The amount specified is higher than the maximum amount for the selected package");
            }
            return this.proceed()
        },
        resetDepositObj(){
            this.deposit = (new Deposit()).data;
            this.deposit.plan = 'BITCOIN';
            this.deposit.package = 'BASIC';
            this.deposit.amountDeposited = this.plans[this.deposit.plan][this.deposit.package].minDeposit

        }
    },
    components: {
      walletAddress
    },
    mounted() {
        this.resetDepositObj()
    }
}
</script>

<style scoped>

</style>