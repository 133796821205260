<template>
    <div class="modal fade" id="walletAddress">
        <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Deposit</h5>
                    <button type="button" class="close" data-dismiss="modal"><span>&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="col-12 text-center">
                        <p>Scan the QR Code below</p>
                    </div>
                    <div class="row justify-content-center">
                        <qr-code :scale="5" :value="'bitcoin:'+address" />
                    </div>
                    <div class="col-12">
                        <p class="text-center">
                            Ensure to pay exactly ${{amount}} into the {{crypto}} address specified below. <br>
                            <small class="text-info">Paying into another wallet will result to loss of funds.</small>
                        </p>
                        <p class="text-center text-primary font-weight-bold">
                            {{address}}
                        </p>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-danger light" data-dismiss="modal">Close</button>
                    <button type="button" @click="copyToClipboard" class="btn btn-info">Copy to clipboard</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "walletAddress",
    props: ['crypto', 'amount', 'address'],
    methods: {
        copyToClipboard(){
            navigator.clipboard.writeText(this.address);
            return toastr.success("Copied to clipboard!")
        }
    },
    mounted() {
        const w_modal = $('#walletAddress');
        w_modal.modal({
            backdrop: 'static',
            show: false
        })
    }
}
</script>

<style scoped>

</style>